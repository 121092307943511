















import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageHeader from '@/components/partials/ImageHeader.vue';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';
import Authservice from '@/assets/services/Authservice';

@Component({
  components: { LinkWithArrow, ImageHeader },
})
export default class RegistrationRoleCard extends Vue {
  @Prop({ required: true })
  private readonly imageSrc!: string;

  @Prop({ required: true })
  private readonly heading!: string;

  @Prop({ required: true })
  private readonly listEntries!: string[];

  @Prop({ required: true })
  private readonly linkText!: string;

  @Prop({ required: true })
  private readonly role!: string;

  private click() {
    this.$emit('click');
  }

  private async addRole(role: string) {
    Authservice.addRole(role);
  }
}
