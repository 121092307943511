





















import { Component, Vue } from 'vue-property-decorator';
import AppCard from '@/components/AppCard.vue';
import ProjectCard from '@/components/projects/ProjectCard.vue';
import RegistrationRoleCard from '@/components/RegistrationRoleCard.vue';
import Authservice from '@/assets/services/Authservice';
import Role from '@/assets/roles';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import Page from '@/components/partials/Page.vue';

@Component({
  components: {
    AppCard, ProjectCard, RegistrationRoleCard, LoadingAnimation, Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class PreRegistrationPage extends Vue {
  private readonly userTexts = [
    'Immer informiert über die neuesten Mobilitätskonzepte',
    'Verwenden Sie ihren Account in angebundenen Apps',
    'An Umfragen teilnehmen und Mobilität aktiv mitgestalten',
  ];

  private readonly developerTexts = [
    'Profitieren Sie vom Single-Sign-On über innovaMo',
    'Marketingwirksame Darstellung ihrer App in der Suche',
    'Erstellen Sie Umfragen für ihre Benutzer',
  ];

  private initialLoading: boolean = true;

  private created() {
    const userRole = Authservice.getUserRole();
    if (userRole.role !== Role.NOROLE) {
      this.$router.push('/');
    }

    this.initialLoading = false;
    if (this.$route.query.session_state && this.$route.query.code) {
      window.location.href = `${process.env.VUE_APP_API_GATEWAY_ONLY_URL}/oauth2/authorization/keycloak?redirect=${process.env.VUE_APP_FRONTEND_URL}/select-registration`;
    }
  }

  private async addRole(role: string) {
    await Authservice.addRole(role);
  }

}

